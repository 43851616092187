import * as React from 'react';
import "./SectionHeader.css?__remix_sideEffect__";

const SectionHeader = ({ eyebrow, headline }) => {
  if (!headline) {
    console.error('SectionHeader component requires a headline prop');
    return false;
  }

  const eyebrowElement = eyebrow ? <h2 className='eyebrow'>{eyebrow}</h2> : false;

  return (
    <div className='SectionHeader'>
      {eyebrowElement}
      <h3 className='headline headline--alt headline--alt-lg'>{headline}</h3>
    </div>);

};
export default SectionHeader;